import React, { useState, useEffect } from 'react'
import Update from '../components/Update'
import { Link, graphql } from "gatsby"
import Hero from '../components/Hero';
import { StaticImage } from 'gatsby-plugin-image';
import SignUpSection from '../components/SignUpSection';

const Column = ({ id, frontmatter } ) => {
  if( !frontmatter.update_headline ) {
    return;
  }

  return(
    <div className="col-md-4">
      <Update id={ id } image={`../${frontmatter.update_image}`} headline={ frontmatter.update_headline } intro={ frontmatter.update_intro } />
    </div>
  )
}

export default function UpdatesList ({ data, pageContext }) {
  const [offset, setOffset] = useState(9);
  const [updates, setUpdates] = useState([]);

  // = = = = = = = = = = = = =
  // Hooks

  // Get more updates when offset state changes
  useEffect( () => getUpdates(), [offset]);


  // = = = = = = = = = = = = =
  // GraphQL

  const initialUpdates = data.updates.nodes;


  // = = = = = = = = 
  // Events Functions

  //
  const handleClick = (e) => {
    e.preventDefault();
    setOffset(offset + 3);
  }

  //
  const getUpdates = () => {
    setUpdates(initialUpdates.slice(0, offset + 3));
  }

  return(
    <>
      <Hero
        headline = {`Updates / ${pageContext.tag}`}
        size = {'medium'}
      >
        <StaticImage 
            className="hero__picture"
            src="../../static/media/updates-hero.jpg" 
            alt="" 
            loading="eager"
        />
      </Hero>

      <section id="paginated-updates" className="paginated-updates paginated-updates--bg">
        
        <div className="container">

          <div className="row">
            { updates.map( update => <Column key={ update.id } id={update.id} frontmatter={update.frontmatter} /> ) }
          </div>

          <div className="row">
            <div className="col text-center">
              {
                initialUpdates.length - offset
                ? <Link onClick={ (e) => handleClick(e) } className="load-more" to="/updates">More</Link>
                : <Link>No more updates</Link>
              }
            </div>
          </div>
              
        </div>
      </section>

      <SignUpSection />
    </>
  )
}


export const query = graphql`
  query UpdateTag($tag: String!) {
    updates: allMarkdownRemark(filter: {frontmatter: {update_tags: {eq: $tag}}}) {
      nodes {
        frontmatter {
          update_headline
          update_image
          update_intro
        }
        id
      }
    }
  }
`;